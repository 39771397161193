<template>
    <div>
        <page-title :heading="this.$t('settings.lang_nav_settings_roomplaner')"
                    :subheading="this.$t('erp.lang_roomPlanerAddRoom')"
                    :icon="icon"
                    show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <room-create-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import RoomCreateComponent from "../../../../components/settings/cashier/TablePlaner/RoomCreateComponent";
    export default {
        name: "TablePlanerCreate",
        components:{
            RoomCreateComponent,
            PageTitle

        },
        data(){
            return  {
                icon: 'pe-7s-monitor icon-gradient bg-tempting-azure',
            }
        }
    }
</script>

<style scoped>

</style>